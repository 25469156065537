<template>
  <b-container fluid>
    
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ count }} Websites</h4>
          </template>
          <template v-slot:headerAction>
            <div class="d-flex align-items-center">
              <div class="input-with-icon mr-3">
                <span class="icon is-small is-left" style="margin-top: 35px;">
                  <i class="fas fa-search"></i>
                </span>
                <input type="search" class="form-control" v-model="searchQuery" @keyup="handleSearch" placeholder="Search">
              </div>
              <b-button variant="primary" @click="openModal" style="border-radius: 5px">Add Website</b-button>
            </div>
          </template>
          
          <template v-slot:body>
            <b-row v-if="loading" >
      <LoadingSpinner :loading="loading" />
    </b-row>
            <b-row v-else >
              <b-col md="12" v-if="!rows.length" class="table-responsive">
                <h5 class="text-center mt-4">{{ response_message }} </h5>
              </b-col>
              <b-col md="12" v-else class="table-responsive">
                <b-table bordered hover :items="rows" :fields="columns" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc">
                  <template v-slot:cell(serialNumber)="data">
                    {{ (currentPage - 1) * itemsPerPage + data.index + 1 }}
                  </template>
                  <template v-slot:cell(countries_name)="data">
                    <span v-if="data.item.countries_name.length <= 10">
                      {{ data.item.countries_name }}
                    </span>
                    <span v-else>
                      {{ data.item.countries_name.substring(0, 10) }}...
                      <a href="#" @click.prevent="showFullText(data.item.countries_name)">Read More</a>
                    </span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <div class="text-left">
                      <b-button v-if="data.item.is_start === true" class="btn btn-success" fdprocessedid="epulve"
                        size="sm" @click="stop_start_process(data.item.public_id, 'stop')"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px"
                        title="Stop Process" :disabled="isSubmitting">
                        <i class="fas fa-stop" style="margin-left: 5px !important;"></i>
                        <!-- Stop font icon -->
                      </b-button>
                      <b-button v-else class="btn btn-success" fdprocessedid="epulve" size="sm"
                        @click="stop_start_process(data.item.public_id, 'start')"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px"
                        title="Start Process" :disabled="isSubmitting">
                        <i class="fas fa-play" style="margin-left: 5px !important;"></i>
                        <!-- Start font icon -->
                      </b-button>
                      <b-button :disabled="data.item.is_start === true" variant="info" size="sm"
                        @click="edit(data.item)"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px"
                        title="Edit">
                        <i class="fas fa-pencil-alt" style="margin-left: 5px !important;"></i>
                        <!-- Edit font icon -->
                      </b-button>
                      <b-button :disabled="data.item.is_start === true" variant="danger" size="sm"
                        @click="remove(data.item.public_id)"
                        style="padding: 4px !important; border-radius: 3px !important; margin-right: 8px; margin-top:5px"
                        title="Delete">
                        <i class="fas fa-trash-alt" style="margin-left: 5px !important;"></i>
                        <!-- Delete font icon -->
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
             
            </b-row>
            
          </template>
          <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="itemsPerPage" @change="onPageChange"
            align="right" class="p-3"  v-if="rows.length" />
        </iq-card>
      </b-col>
    </b-row>
    <!-- Add/Edit Modal -->
    <b-modal v-model="modalOpen" :title="editMode ? 'Edit Website' : 'Add  Website'"
      :ok-title="editMode ? 'Update' : 'Save'" @ok="submitForm" no-close-on-backdrop no-close-on-esc
      @hide="checkModalClose" :disabled="isSubmitting">
      <b-form @submit.stop.prevent="submitForm" :valid="isFormValid">
        <div class="row">
          <b-form-group label="Name" label-for="nameInput" :state="nameState"
            invalid-feedback="Name should be 2 to 50 characters long and must contain at least one non-numeric character." class="col-md-6">
            <b-form-input id="nameInput" maxlength="50" @keydown="preventLeadingSpace($event, 'name')"
              v-model="newWebsite.name" style="border-color:lightgray; height:38px;" required max></b-form-input>
          </b-form-group>
          <b-form-group label="Website" label-for="websiteInput" :state="websiteState"
            invalid-feedback="Website is required" class="col-md-6">
            <b-form-input id="websiteInput" @keydown="preventLeadingSpace($event, 'website')"
              v-model="newWebsite.website" style="border-color:lightgray ; height:38px;" required maxlength="80"
              placeholder="www.xyz.com"></b-form-input>
          </b-form-group>
          <b-form-group label="Locations" label-for="locationsInput" :state="locationsState"
            invalid-feedback="Locations are required" class="col-md-12">
            <treeselect id="locationsInput1" v-model="selectedCity" :options="countryOptions" multiple
              placeholder="Select locations" no-options-text="No locations found"
              style="border-color:lightgray;" required >
            </treeselect>
          </b-form-group>
          <b-form-group label="Fail Threshold" label-for="failThresholdInput" :state="failThresholdState"
            invalid-feedback="Fail Threshold is required" class="col-md-4">
            <b-form-input id="failThresholdInput" @keydown="preventLeadingSpace($event, 'fail_threshold')"
              v-model="newWebsite.fail_threshold" type="number" style="border-color:lightgray ; height:38px;" required
              @keypress="validateNumberInput" min="0" ></b-form-input>
          </b-form-group>
          <b-form-group label="Frequency" label-for="frequencyIpInput" :state="frequencyState"
            invalid-feedback="Frequency is required" class="col-md-4">
            <b-form-select id="frequencyIpInput" v-model="newWebsite.ping_frequency" :options="frequencys"
              style="border-color:lightgray"  required ></b-form-select>
          </b-form-group>
          <b-form-group label="Type" label-for="typeIpInput" :state="typeState" invalid-feedback="Type is required"
            class="col-md-4">
            <b-form-select id="typeIpInput" v-model="newWebsite.type" :options="types"
              style="border-color:lightgray; height:38px;" required></b-form-select>
          </b-form-group>
        </div>
      </b-form>
    </b-modal>
    <b-modal v-model="showFullTextModal" title="Countries" hide-footer>
      <p>{{ fullText }}</p>
    </b-modal>
  </b-container>
</template>
<script>
import axios from "axios";
import { core } from "../../../config/pluginInit";
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import LoadingSpinner from "@/config/Pageloader.vue";
import Swal from 'sweetalert2';
import { BPagination } from "bootstrap-vue";
import constant, { decryptToken, response_message, toastMessage } from "@/config/constant";
export default {
  name: "Website",
  components: {
    Treeselect, BPagination,
    LoadingSpinner,
  },
  data() {
    return {
      showFullTextModal: false,
      fullText: '',
      isSubmitting: false, // Define isSubmitting in the data object
      columns: [
        { label: 'S. No.', key: 'serialNumber', class: 'text-left' },
        { label: "Name", key: "name", class: "text-left", sortable: true },
        { label: "Website", key: "website", class: "text-left", sortable: true },
        { label: "Countries", key: "countries_name", class: "text-left", sortable: true },
        // { label: "Frequency", key: "ping_frequency", class: "text-left", sortable: true },
        // { label: "Fail Threshold", key: "fail_threshold", class: "text-left", sortable: true },
        { label: "Type", key: "type", class: "text-left", sortable: true },
        { label: "Action", key: "action", class: "text-left" },
      ],
      sortBy: '',
      sortDesc: false,
      preventModalClose: false,
      nameError:'',
      searchQuery: '',
      nameState: null,
      websiteState: null,
      locationsState: null,
      failThresholdState: null,
      frequencyState: null,
      typeState: null,
      currentPage: 1,
      itemsPerPage: 20,
      totalRows: 0,
      response_message: response_message,
      message: "",
      icon: "",
      count: 0,
      countryOptions: [],
      selectedCity: [],
      rows: [], // Initialize the rows array to be empty initially
      modalOpen: false,
      editMode: false,
      loading: true,
      newWebsite: {
        name: "",
        website: "",
        sensor_ip: "",
        ping_frequency: "",
        countries: "",
        type: "",
      },
      frequencys: [
        { text: "5 minutes", value: "5" },
        { text: "10 minutes", value: "10" },
        { text: "20 minutes", value: "20" },
        { text: "30 minutes", value: "30" },
      ],
      types: [
        { text: "Http", value: "http" },
        { text: "Https", value: "https" },
      ], // // Initialize the Fail Thresholds array
    };
  },
  computed: {
    isFormValid() {
      return (
        this.nameState &&
        this.websiteState &&
        this.locationsState &&
        this.failThresholdState &&
        this.frequencyState &&
        this.typeState
      );
    },
  },
  methods: {
    // First letter space not allowed
    preventLeadingSpace(event, key) {
      if (!this.newWebsite) {
        this.newWebsite = {
          name: "",
          website: "",
          locations: [],
          fail_threshold: 0,
          ping_frequency: null,
          type: null,
        };
      }

      if (event.target.selectionStart === 0 && event.keyCode === 32) {
        event.preventDefault();
      }
    },
    //Input type length
    limitInputLength(event, maxLength) {
      if (event.target.value.length >= maxLength) {
        event.preventDefault();
      }
    },
    // Call fetchData with the searchQuery
    handleSearch() {
      this.fetchData(this.currentPage, this.searchQuery);
      this.loading = true;
    },
    //In popup model show text
    showFullText(text) {
      this.fullText = text;
      this.showFullTextModal = true;
    },
     //Cancel form
    cancelForm() {
      this.modalOpen = false;
      this.clearForm(); // Optionally clear the form when canceling
    },

    async fetchCountryOptions() {
      // Fetch country option
      try {
        const token = localStorage.getItem("token");
        const key = "enc"; // Fetch encrypted token
        const decryptedToken = decryptToken(token, key); // Decrypt token

        const response = await axios.get(
          constant.API_BASE_URL + "domain/countries/",
          {
            headers: {
              Authorization: "Token " + decryptedToken,
            },
          }
        );

        const countries = response.data.data; // Extract the countries array from the response
        const cityOptions = countries.map((country) => ({
          label: country.city,
          id: country.id,
        })); // Map the cities to text-value pairs

        // Update the dropdown options with the cities
        this.countryOptions = cityOptions;
      } catch (error) {
        console.error("Error fetching country options:", error);
      }
    },

    async fetchData(pageNumber, searchQuery = '') {
      //Fetch website data
      try {
        const token = localStorage.getItem("token");
        const key = "enc"; // Fetch encrypted token
        const decryptedToken = decryptToken(token, key); // Decrypt token


        const response = await axios.get(
          constant.API_BASE_URL + "web-monitoring/web-monitors/", {
          params: {
            page: pageNumber,
            search: searchQuery,
            ordering: "-created_at"

          },
          headers: {
            Authorization: "Token " + decryptedToken,
          },
        }
        );
        this.count = response.data.count
        this.totalRows = response.data.count;
        if (response.data && Array.isArray(response.data.data)) {
          this.rows = response.data.data.map((item) => ({
            ...item,
            // Directly use countries_name since it's already an array
            countries_name: item.countries_name.join(", "),
          }));
          this.loading = false;
        } else {
          this.loading = false;
        }
      } catch (error) {
        this.rows = [];
        this.loading = false;
      }
    },
    onPageChange(pageNumber) {
      //paginestion
      this.currentPage = pageNumber;
      this.fetchData(pageNumber, this.searchQuery); // Fetch data for the new page
    },
    validateNumberInput(event) {
      //validetions
      const charCode = event.keyCode;
      // Allow backspace (8) and delete (46) keys
      if (charCode === 8 || charCode === 46) {
        return true;
      }
      // Allow numbers (48-57) and the period (46) for decimal values
      if (charCode >= 48 && charCode <= 57 || charCode === 46) {
        return true;
      }
      // Prevent any other key from being pressed
      event.preventDefault();
    },

    async submitForm() {
      // Website add and edit 
      try {
        const isNumericName = /^\d+$/.test(this.newWebsite.name);
        if (isNumericName) {
          // Display error message if name contains only numeric characters
          this.nameError = "Name should not contain only numeric characters.";
          this.nameState = false;
          // Prevent modal from closing
          this.preventModalClose = true;
          return; // Stop execution
        }
        else {
          this.nameError = '';
        }
        this.nameState = (this.newWebsite.name && this.newWebsite.name.length >= 2 && this.newWebsite.name.length <= 50) ? true : false;

        this.websiteState = (this.newWebsite.website ) ? true : false;

        this.locationsState = this.selectedCity.length > 0 ? true : false;
        this.failThresholdState = this.newWebsite.fail_threshold ? true : false;
        this.frequencyState = this.newWebsite.ping_frequency ? true : false;
        this.typeState = this.newWebsite.type ? true : false;
        if (this.isFormValid) {
          this.isSubmitting = true; // Enable the submit button
          const token = localStorage.getItem("token");
          const key = "enc"; // Fetch encrypted token

          const decryptedToken = decryptToken(token, key); // Decrypt token
          const { name, website, ping_frequency, fail_threshold } = this.newWebsite;

          // Set the selected city into an array
          const countries = this.selectedCity;

          const requestData = {
            name: name,
            website: website,
            countries: countries,
            ping_frequency: ping_frequency,
            fail_threshold: fail_threshold,
            type: this.newWebsite.type,
          };

          // Set the token in the request headers
          const headers = {
            Authorization: "Token " + decryptedToken,
          };

          let response;
          if (this.editMode) {
            // Edit mode
            response = await axios.put(
              constant.API_BASE_URL +
              "web-monitoring/web-monitors/" +
              this.newWebsite.public_id +
              "/",
              requestData,
              { headers }
            );
          } else {
            // Add mode
            response = await axios.post(
              constant.API_BASE_URL + "web-monitoring/web-monitors/",
              requestData,
              { headers }
            );
          }
          this.message = response.data.message

          // Set the icon to "success" for successful response
          this.icon = "success"
          // Call the toastMessage function to display a toast notification
          toastMessage(this.icon, this.message);
          await this.fetchData();
          this.modalOpen = false;
          this.clearForm();
          this.isSubmitting = false;
        }
        else {
          this.preventModalClose = true;
          return false;
        }
      } catch (error) {
        //this.message = error.response.data.non_field_errors;
        if (error.response && error.response.data && error.response.data.message) {
          this.message = error.response.data.message;
        }
        else if (error.response && error.response && error.response) {
          this.message = error.response.data.errors.name;
        }
        else {
          this.message = "Something went wrong. Please try again later.";
        }
        // Set the icon to "success" for successful response
        this.icon = "error"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);
        this.isSubmitting = false;

      }
    },
    checkModalClose(event) {
      //popup model close
      if (this.preventModalClose) {
        event.preventDefault(); // Prevent the modal from closing
        this.preventModalClose = false; // Reset the flag
      }
    },
    openModal() {
      // valedition error message cler when popup model open
      this.nameState = null;
      this.websiteState = null;
      this.locationsState = null;
      this.failThresholdState = null;
      this.frequencyState = null;
      this.typeState = null;
      // Clear the message
      this.message = "";
      this.modalOpen = true;
      this.editMode = false;
      this.clearForm();

      this.newWebsite.ping_frequency = "5";
      this.newWebsite.fail_threshold = "2";
      this.newWebsite.type = "http";
      // this.isFormValid = false;
    },
    edit(item) {
      this.newWebsite = { ...item };
      this.selectedCity = item.countries;
      this.nameState = this.newWebsite.name ? true : false;
      this.websiteState = this.newWebsite.website ? true : false;
      this.locationsState = this.selectedCity.length > 0 ? true : false;
      this.failThresholdState = this.newWebsite.fail_threshold ? true : false;
      this.frequencyState = this.newWebsite.ping_frequency ? true : false;
      this.typeState = this.newWebsite.type ? true : false;
      this.modalOpen = true;
      this.editMode = true;
    },
    remove(public_id) {
      // Delete website
      try {
        let self = this;
        Swal.fire({
          toast: true,
          position: "top",
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            const token = localStorage.getItem("token");
            const key = "enc";
            const decryptedToken = decryptToken(token, key);
            axios.delete(
              constant.API_BASE_URL +
              "web-monitoring/web-monitors/" +
              public_id +
              "/",
              {
                headers: {
                  Authorization: "Token " + decryptedToken,
                },
              }
            ).then(response => {
              this.message = response.data.message
              // Set the icon to "success" for successful response
              this.icon = "success"
              // Call the toastMessage function to display a toast notification
              toastMessage(this.icon, this.message);
              self.fetchData();
              // Do something with the response data if needed
            })
          }
        });
      } catch (error) {
        this.message = "Error deleting website"
        // Set the icon to "success" for successful response
        this.icon = "error"
        // Call the toastMessage function to display a toast notification
        toastMessage(this.icon, this.message);
        console.error("Error deleting website:", error);
      }
    },

    async stop_start_process(public_id, action) {
  try {
    let self = this;
    const isStart = action === 'start';
    const result = await Swal.fire({
      toast: true,
      position: 'top',
      title: `Are you sure you want to ${isStart ? 'start' : 'stop'} the process?`,
      text: `This action will ${isStart ? 'start' : 'stop'} the process.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: `Yes, ${isStart ? 'start' : 'stop'} it!`
    });

    if (result.isConfirmed) {
      this.isSubmitting = true;
      const token = localStorage.getItem("token");
      const key = "enc";
      const decryptedToken = decryptToken(token, key);
      await axios.get(
        constant.API_BASE_URL +
        `web-monitoring/web-monitors/${public_id}/${action}/`,
        {
          headers: {
            Authorization: "Token " + decryptedToken,
          },
        }
      );
      await this.fetchData();
      this.isSubmitting = false;
      // Optional: Show success message after action completes
      Swal.fire({
        toast: true,
        position: 'top',
        title: `${isStart ? 'Process started' : 'Process stopped'} successfully!`,
        icon: 'success'
      });
    }
  } catch (error) {
    console.error(`Error ${isStart ? 'starting' : 'stopping'} process:`, error);
    Swal.fire({
      toast: true,
      position: 'top',
      title: `Error ${isStart ? 'starting' : 'stopping'} process`,
      text: error.message || "An error occurred",
      icon: 'error'
    });
    this.isSubmitting = false;
  }
},

    clearForm() {
      this.newWebsite = {
        name: "",
        website: "",
        sensor_ip: "",
        ping_frequency: "",
        type: "",
        code: "",
      };
      if (!this.editMode) {
        // Reset or set defaults for 'Add' mode
        this.selectedCity = [];
        // More default settings can be initialized here
      }
    },
  },
  mounted() {
    this.fetchData(this.currentPage);
    this.fetchCountryOptions();
    core.index();
  },
};
</script>
